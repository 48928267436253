import { Fragment, useState, useCallback, useEffect, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import purple_mark from "./../../../assets/icons/Map/purple_mark_icon.svg";
import classes from "./MapPOIS.module.css";

import {
  CustomMapHotspots,
  GoogleMapVehicles,
  GoogleMapWifiHotSpot,
  GoogleMapGarbage,
  GoogleMapHydroMeters,
} from "../../Map/GoogleMapStyles";

import { useStore } from "../../../hooks/store";
import BoxTitle from "../../box/title/BoxTitle";
import { LoadingCircle } from "../../UI/spinners/LoadingSpinners";

const cityCenter = {
  // lat: 40.62792947617496,
  // lng: 22.444795864427686
  // lat: 40.50237612820603,
  //   lng:  22.921787852227137

  lat: 40.504921,
  lng: 22.92798,
};

const MapPOIS = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQlCnn8F1D0S9ymnWZwc_WFx2ZS6g8w1Q",
    // ...otherOptions
  });
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const state = useStore()[0];
  const typeRef = useRef(null);
  const mapStyleRef = useRef(null);
  const centerMapRef = useRef(cityCenter);

  useEffect(() => {
    if (state.types.length > 0) {
      for (let y = 0; y < state.types.length; y++) {
        if (state.types[y].id === props.typeId) {
          typeRef.current = state.types[y];
          break;
        }
      }
    }
  }, [state.types]);

  useEffect(() => {
    if (state.types.length > 0) {
      const data = state.sensors.filter((item) => item.typeId === props.typeId);
      for (let i = 0; i < data.length; i++) {
        if (data[i].position) {
          centerMapRef.current = data[i].position;
          break;
        }
      }
      setMarkers(data);
    }
  }, [state.types, state.sensors]);

  useEffect(() => {
    if (props.typeId === "7") mapStyleRef.current = CustomMapHotspots;
    else if (props.typeId === "8") mapStyleRef.current = GoogleMapVehicles;
    else if (props.typeId === "4") mapStyleRef.current = GoogleMapWifiHotSpot;
    else if (props.typeId === "9") mapStyleRef.current = GoogleMapGarbage;
    else if (props.typeId === "10") mapStyleRef.current = GoogleMapHydroMeters;
    else mapStyleRef.current = CustomMapHotspots;
  }, []);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(map);
  }, []);

  console.log(markers);
  return (
    <Fragment>
      <BoxTitle
        className={classes.map_title_style}
        icon={typeRef.current && typeRef.current.icon}
        title={props.title}
        infoContext={typeRef.current && typeRef.current.tooltip}
        link={`/map?typeId=${props.typeId}`}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={props.typeId}
      />
      {isLoaded && (
        <div className={classes.wrapper}>
          <GoogleMap
            // center={centerMapRef.current}
            center={cityCenter}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              styles: mapStyleRef.current,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
          >
            {/* Child components, such as markers, info windows, etc. */}

            <Fragment>
              {markers.map((marker) => (
                <Marker
                  key={marker.id}
                  position={marker.position}
                  animation={4}
                  clickable={true}
                  icon={marker.smallMarker ? marker.smallMarker : purple_mark}
                  onClick={() => {
                    setInfoWindowData(marker.id);
                  }}
                >
                  {infoWindowData && marker.id === infoWindowData && (
                    <InfoWindow>
                      <p>{marker.title}</p>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </Fragment>

            <></>
          </GoogleMap>
        </div>
      )}
      {!isLoaded && <LoadingCircle />}
    </Fragment>
  );
};

export default MapPOIS;
